import React from "react"
import styled from "styled-components"

type InputBoxProps = {
  label: string,
  htmlFor: string,
  children: React.ReactNode,
  className?: string
}

const Text = styled.p`
  margin-top: -22px;
  margin-bottom: 1px !important;
`

const InputBox: React.FC<InputBoxProps> = ({
  label, htmlFor, className, children
}) => (
  <label
    htmlFor={htmlFor}
    className={`text-alt relative block mb-6 border border-solid border-alt px-4 py-2 ${className || ""}`}
  >
    <Text className="relative bg-white px-4 table mx-auto tracking-wide text-lg lowercase small-caps">
      {label}
    </Text>
    {children}
  </label>
)

export default InputBox
