// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-leilighetene-page-tsx": () => import("./../src/templates/leilighetene-page.tsx" /* webpackChunkName: "component---src-templates-leilighetene-page-tsx" */),
  "component---src-templates-kvaliteter-page-tsx": () => import("./../src/templates/kvaliteter-page.tsx" /* webpackChunkName: "component---src-templates-kvaliteter-page-tsx" */),
  "component---src-templates-takk-for-interessen-page-tsx": () => import("./../src/templates/takk-for-interessen-page.tsx" /* webpackChunkName: "component---src-templates-takk-for-interessen-page-tsx" */),
  "component---src-templates-prosjektet-page-tsx": () => import("./../src/templates/prosjektet-page.tsx" /* webpackChunkName: "component---src-templates-prosjektet-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-modellen-tsx": () => import("./../src/pages/modellen.tsx" /* webpackChunkName: "component---src-pages-modellen-tsx" */)
}

