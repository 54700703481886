import React, {
  useState, FC, Dispatch, SetStateAction, useEffect
} from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

interface PageHeaderProps {
  setShowInteresse: Dispatch<SetStateAction<boolean>>
}

const PageHeader: FC<PageHeaderProps> = ({ setShowInteresse }) => {
  const [ showNav, setShowNav ] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setShowNav(false)
  }, [ location.pathname ])

  return (
    <header
      className="px-12 pt-2 font-semibold text-black bg-white fixed top-0 inset-x-0 z-10 md:px-4 sm:static"
      style={{ fontSize: 17, boxShadow: "0 0 30px -22px #000" }}
    >
      <div className="mx-auto w-full max-w-5xl">
        <div className="flex justify-center sm:justify-between">
          <a
            href="https://lochenveien.bonum.no/"
            title="Til Løchenveien"
            className="pb-3 block text-center font-secondary hover:opacity-50 font-normal sm:text-left"
            id="header-logo-link"
          >
            <p className="text-3xl uppercase tracking-wide mb-0">
              Løchenveien
            </p>
            <p className="text-base uppercase -mt-2 mb-0">
              Bygdøy
            </p>
          </a>

          <button
            className="hidden sm:inline-block text-black p-2"
            onClick={() => { setShowNav(!showNav) }}
            id="header-menu-button"
          >
            <hr className="mx-auto w-4 h-px bg-black" />
            <hr className="mx-auto w-4 h-px bg-black my-1" />
            <hr className="mx-auto w-4 h-px bg-black" />
          </button>
        </div>

        <nav
          role="navigation"
          className={`pt-2 sm:pt-0 border-t border-solid border-alt w-full sm:hidden ${showNav ? "block-important" : ""}`}
        >
          <div className="mx-auto w-full max-w-3xl font-semibold px-5 md:px-0">
            <div className="flex items-center justify-between lowercase small-caps text-gray-700 sm:flex-col sm:text-center sm:py-4">
              <Link
                to="/"
                className="pb-1 px-1 sm:pb-0 sm:my-1 border-b-2 border-solid border-white"
                activeClassName="text-black border-alt"
                id="header-prosjektet-link"
              >
                Løchenveien
              </Link>
              <Link
                to="/leilighetene"
                className="pb-1 px-1 sm:pb-0 sm:my-1 border-b-2 border-solid border-white"
                activeClassName="text-black border-alt"
                id="header-leilighetene-link"
              >
                Leilighetene
              </Link>
              <Link
                to="/kvaliteter"
                className="pb-1 px-1 sm:pb-0 sm:my-1 border-b-2 border-solid border-white"
                activeClassName="text-black border-alt"
                id="header-kvaliteter-link"
              >
                Kvaliteter
              </Link>
              <Link
                to="/modellen"
                className="pb-1 px-1 sm:pb-0 sm:my-1 border-b-2 border-solid border-white"
                activeClassName="text-black border-alt"
                id="header-modellen-link"
              >
                Se modellen
              </Link>
              <button
                className="bg-alt hover:bg-alt-800 lowercase px-4 text-white uppercase hover:opacity-50 md:px-2 mb-1 sm:mb-0 sm:py-2"
                onClick={() => { setShowInteresse(true) }}
                style={{ font: "inherit" }}
                id="header-meld-interesse-button"
              >
                Meld interesse
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default PageHeader
