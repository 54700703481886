import React, { FC, Dispatch, SetStateAction } from "react"
import Image from "../components/Image"
import { Link } from "gatsby"

interface PageFooterProps {
  setShowInteresse: Dispatch<SetStateAction<boolean>>
}

const PageFooter: FC<PageFooterProps> = ({ setShowInteresse }) => (
  <>
    <section
      id="cta"
      className="mb-16 px-8 lg:px-4"
    >
      <div className="flex flex-col items-center text-center relative">
        <svg
          className="h-12 w-32 bg-white absolute px-12 sm:px-8 sm:w-24"
          style={{ top: "-1.5rem" }}
          viewBox="0 0 508.044 508.044"
        >
          <path
            d="M507.93,155.673c0-0.055,0.006-0.11,0.006-0.165c0-66.793-54.145-120.938-120.938-120.938S266.061,88.714,266.061,155.508 c0,66.794,54.15,120.938,120.938,120.938c13.727,0,26.867-2.393,39.162-6.609c-27.209,156.09-148.93,256.752-36.096,173.905 C515.182,351.874,508.07,159.198,507.93,155.673z"
          />
          <path
            d="M120.938,276.445c13.727,0,26.867-2.393,39.168-6.609c-27.216,156.09-148.937,256.752-36.102,173.905 c125.117-91.867,118.006-284.543,117.865-288.068c0-0.055,0.006-0.11,0.006-0.165c0-66.793-54.144-120.938-120.937-120.938 C54.144,34.57,0,88.714,0,155.508C0,222.302,54.15,276.445,120.938,276.445z"
          />
        </svg>

        <div className="w-full max-w-5xl pt-10 pb-8 border-t border-solid border-black">
          <p className="w-full max-w-3xl mx-auto font-italic text-xl">
            Bonum sin omfattende portefølje inkluderer en rekke store utviklingsprosjekter, men det er våre signaturprosjekter som Løchenveien som gjør oss spesielle.
          </p>
        </div>

        <a
          className="text-base font-semibold px-8 py-2 uppercase tracking-wide mx-auto block text-white bg-alt hover:opacity-50"
          href="https://bonum.no/prosjekter"
          id="footer-cta-prosjekter-link"
          target="_blank"
          rel="noopener"
        >
          Se våre prosjekter
        </a>
      </div>
    </section>

    <footer className="text-gray-600 bg-black">
      <section className="w-full px-6 py-12 text-center border-b-2 border-gray-900 border-solid hidden sm:block">
        <h3 className="mb-6 text-white tracking-widest uppercase">
          Fortsett her
        </h3>

        <nav
          role="navigation"
          className="mx-auto w-full max-w-md flex justify-between lowercase small-caps font-semibold sm:flex-col"
        >
          <Link
            to="/"
            className="py-1"
            activeClassName="text-white"
            id="footer-prosjektet-link"
          >
            Løchenveien
          </Link>
          <Link
            to="/leilighetene"
            className="py-1"
            activeClassName="text-white"
            id="footer-leilighetene-link"
          >
            Leilighetene
          </Link>
          <Link
            to="/kvaliteter"
            className="py-1"
            activeClassName="text-white"
            id="footer-kvaliteter-link"
          >
            Kvaliteter
          </Link>
          <Link
            to="/modellen"
            className="py-1"
            activeClassName="text-white"
            id="footer-modellen-link"
          >
            Se modellen
          </Link>
        </nav>
      </section>

      <section className="w-full px-6 py-16 text-white text-center">
        <div className="mx-auto w-full max-w-lg">
          <h2 className="subtitle text-3xl text-white">
            Ønsker du flere detaljer?
          </h2>

          <hr className="my-8 w-8 sm:my-5 pt-px bg-white h-px mx-auto" />

          <div className="mx-auto w-full max-w-2xl">
            <p>
              Ta kontakt med eiendomsmegler Tom Elliot Johnsen på <br /><a id="footer-megler-phone-link" href="tel://004792213820">922 13 820</a> eller <a id="footer-megler-email-link" href="mailto:tej@sem-johnsen.no">tej@sem-johnsen.no</a>.
              <br />
              <br />
              Du kan også ta kontakt med eiendomsmegler Trond Larsen på <br /><a id="footer-megler-phone-link-2" href="tel://004795908488">95908488</a> eller <a id="footer-megler-email-link-2" href="mailto:tl@sem-johnsen.no">tl@sem-johnsen.no</a>.
            </p>
          </div>

          <button
            className="mt-8 sm:mt-5 px-8 py-2 bg-alt hover:bg-alt-800 hover:opacity-50 small-caps text-white uppercase tracking-wide"
            onClick={() => { setShowInteresse(true) }}
            id="footer-meld-interesse-button"
          >
            Meld interesse
          </button>
        </div>
      </section>

      <section
        className="w-full px-6 pt-8 pb-16 border-t-2 border-b-2 border-gray-900 border-solid"
        style={{ fontSize: 17 }}
      >
        <div className="mx-auto w-full max-w-3xl flex justify-between flex-wrap">
          <nav
            role="navigation"
            className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
          >
            <Image
              src="/img/bonum-logo.png"
              alt="Bonum logo"
              className="mb-3 xs:mx-auto"
              style={{
                maxHeight: 30
              }}
            />

            <a id="footer-bonum-email-link" className="mb-1 block" target="_blank" rel="noopener" href="mailto:kontakt@bonum.no">kontakt@bonum.no</a>
            <a id="footer-bonum-phone-link" className="mb-1 block" target="_blank" rel="noopener" href="tel://004722232385">+47 222–32–385</a>
          </nav>

          <nav
            role="navigation"
            className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
          >
            <h3 className="mb-6 text-lg text-white tracking-widest uppercase">
              Bonum
            </h3>
            <a id="footer-bonum-selskapet-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no">Om Selskapet</a>
            <a id="footer-bonum-utvikling-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/utvikling">Bonum Utvikling</a>
            <a id="footer-bonum-ansatte-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/ansatte">Våre ansatte</a>
            <a id="footer-bonum-stillinger-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/karriere">Se stillinger</a>
            <a id="footer-bonum-media-link" className="" target="_blank" rel="noopener" href="https://bonum.no/media">Media</a>
          </nav>

          <nav
            role="navigation"
            className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
          >
            <h3 className="mb-6 text-lg text-white tracking-widest uppercase">
              Utforsk
            </h3>
            <a id="footer-bonum-prosjekter-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/prosjekter">Alle Prosjekter</a>
            <a id="footer-bonum-holgerslystveien-link" target="_blank" rel="noopener" href="https://holgerslystveien.bonum.no">Holgerslystveien</a>
            <a id="footer-bonum-eugeniesgate-link" className="mb-1 block" target="_blank" rel="noopener" href="https://eugeniesgate.bonum.no">Eugenies gate</a>
            <a id="footer-bonum-vakeroveien-link" className="mb-1 block" target="_blank" rel="noopener" href="https://vakeroveien.bonum.no">Vækerøveien</a>
            <a id="footer-bonum-akersveien-link" className="mb-1 block" target="_blank" rel="noopener" href="https://akersveien.bonum.no">Akersveien</a>
          </nav>

          <nav
            role="navigation"
            className="mt-6 sm:w-1/2 xs:w-full xs:text-center"
          >
            <h3 className="mb-6 text-lg text-white tracking-widest uppercase">
              Websiden
            </h3>
            <a id="footer-bonum-personvern-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/personvern">Personvern</a>
            <a id="footer-bonum-cookies-link" className="" target="_blank" rel="noopener" href="https://bonum.no/cookies">Cookies</a>
          </nav>
        </div>
      </section>

      <section className="w-full px-6 py-8 text-center text-sm">
        <p>
          Bonum © 2019
        </p>
      </section>
    </footer>
  </>
)

export default PageFooter
