import React from "react"
import Dialog from "./Dialog"
import InterestForm from "./InterestForm"

interface InterestDialogProps {
  shown: boolean,
  onOpen: () => void,
  onClose: () => void
}

const InterestDialog: React.FC<InterestDialogProps> = ({
  shown, onOpen, onClose
}) => {
  const open = () => {
    onOpen()
    if(!window.dataLayer) return
    window.dataLayer.push({
      "event": "interest-dialog_open"
    })
  }

  const close = () => {
    onClose()
    if(!window.dataLayer) return
    window.dataLayer.push({
      "event": "interest-dialog_close"
    })
  }

  return (
    <Dialog
      title="Meld din interesse"
      shown={shown}
      alwaysRender={true}
      closeOnOverlay={false}
      width={400}
      onOpen={open}
      onClose={close}
    >
      <div className="p-6 pt-8 bg-white text-black">
        <InterestForm
          onSuccess={onClose}
        />
      </div>
    </Dialog>
  )
}

export default InterestDialog
