const parse = <T>(s: string): T | null => {
  if(!s || s === "") return null

  const j = decodeURI(s.substring(1))
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"')

  return JSON.parse(`{"${j}"}`)
}

const stringify = (o: Object): string => {
  return Object.entries(o)
    .filter(([ key, value ]) => {
      if(value === false || value === 0) return true
      if(value && value.length === 0) return false
      return !!value
    })
    .map(([ key, value ]) => `${key}=${value}`).join("&")
}

export default {
  parse,
  stringify
}
