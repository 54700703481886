import React, { FC, useState, useEffect } from "react"
import Helmet from "react-helmet"
import palette from "../design/palette"
import Styles from "../design/styles"
import SEO from "../components/SEO"
import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import InterestDialog from "../components/InterestDialog"
import styled from "styled-components"
import "react-toastify/dist/ReactToastify.min.css"
import { toast } from "react-toastify"
import searchUrl from "../utils/parseSearchUrl"
import { useNavigate } from "@reach/router"

const PageWrapper = styled.div`
  padding-top: 128px;
  @media(max-width: 639px) {
    padding-top: 0;
  }
`

toast.configure({
  hideProgressBar: true
})

interface DefaultLayoutProps {
  location: {
    search: string
  }
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ children, location }) => {
  const [ showMeldInteresse, setShowInteresse ] = useState(false)
  const [ params, setParams ] = useState()
  const navigate = useNavigate()

  const onOpenMeldInteresse = () => {
    navigate("?" + searchUrl.stringify({ ...params, "meld-interesse": 1 }), {
      replace: false
    })
  }

  const onCloseMeldInteresse = () => {
    navigate("?" + searchUrl.stringify({ ...params, "meld-interesse": 0 }), {
      replace: false
    })
    setShowInteresse(false)
  }

  useEffect(() => {
    const p = searchUrl.parse<{ [k: string]: string }>(location.search)
    if(!p) return
    setParams(p)
    if(p["meld-interesse"] === "1") setShowInteresse(true)
    else if(p["meld-interesse"] === "0") setShowInteresse(false)
  }, [ location.search ])

  return (
    <div id="default-layout">
      <SEO />

      <Helmet>
        <html lang="no" />
        <meta name="theme-color" content={palette.accent} />
        <link rel="icon" type="image/png" href="/img/bonum-icon.png" />
      </Helmet>

      <Styles />

      <PageHeader
        setShowInteresse={setShowInteresse}
      />

      <PageWrapper className="pb-2 px-8 lg:px-4">
        {children}
      </PageWrapper>

      <PageFooter
        setShowInteresse={setShowInteresse}
      />

      <InterestDialog
        shown={showMeldInteresse}
        onOpen={onOpenMeldInteresse}
        onClose={onCloseMeldInteresse}
      />
    </div>
  )
}

export default DefaultLayout
