import React, { FC, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import GdprDialog from "./GdprDialog"
import InputBox from "./InputBox"
import { useNavigate } from "@reach/router"

const API_URL = process.env.GATSBY_API_URL
const PROJECT_ID = process.env.GATSBY_PROJECT_ID

interface InterestFormFields {
  firstName?: string,
  lastName?: string,
  email?: string,
  phoneNumber?: string,
  message?: string,
  sms?: boolean,
  tips?: boolean,
  newsletter?: boolean
}

type InterestFormProps = {
  onSuccess?: () => void
}

const InterestForm: FC<InterestFormProps> = ({ onSuccess }) => {
  const { register, handleSubmit, errors } = useForm()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ showGdprDialog, setShowGdprDialog ] = useState(false)
  const [ formData, setFormData ] = useState()
  const navigate = useNavigate()

  const submit = async () => {
    setIsLoading(true)
    const data = {
      source: "bonum",
      customer: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber
      },
      message: formData.message,
      options: {
        sms: formData.sms,
        tips: formData.tips,
        newsletter: formData.newsletter
      }
    }

    try {
      const res = await fetch(`${API_URL}/hovedsider/projects/${PROJECT_ID}/interest`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })

      const result = await res.json()

      if(!res.ok) {
        throw new Error(result.message || "En ukjent feil oppstod")
      }

      if(onSuccess) onSuccess()
      toast.success("Du er nå meldt opp som interessent")
      setShowGdprDialog(false)
      navigate("/takk-for-interessen")
    } catch(e) {
      toast.error(e.message)
    }
    setIsLoading(false)
  }

  const onSubmit = (data: InterestFormFields): void => {
    setFormData(data)
    setShowGdprDialog(true)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputBox
          htmlFor="firstName"
          label="Fornavn"
        >
          <input
            className="text-center w-full tracking-tight text-xl"
            name="firstName"
            id="firstName"
            placeholder="Skriv inn ditt fornavn"
            ref={register({ required: true })}
          />

          {errors.firstName && (
            <p
              className="text-red-600 text-xs w-full absolute inset-x-0"
              style={{ bottom: -22 }}
            >
              Vennligst fyll inn ditt fornavn
            </p>
          )}
        </InputBox>

        <InputBox
          htmlFor="lastName"
          label="Etternavn"
          className="mt-6 sm:mt-8"
        >
          <input
            className="text-center w-full tracking-tight text-xl"
            name="lastName"
            id="lastName"
            placeholder="Skriv inn ditt etternavn"
            ref={register({ required: true })}
          />

          {errors.lastName && (
            <p
              className="text-red-600 text-xs w-full absolute inset-x-0"
              style={{ bottom: -22 }}
            >
              Vennligst fyll inn ditt etternavn
            </p>
          )}
        </InputBox>

        <InputBox
          htmlFor="email"
          label="E-post"
          className="mt-6 sm:mt-8"
        >
          <input
            className="text-center w-full tracking-tight text-xl"
            name="email"
            id="email"
            type="email"
            placeholder="Skriv inn din e-post adresse"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
          />

          {errors.email && (
            <p
              className="text-red-600 text-xs w-full absolute inset-x-0"
              style={{ bottom: -22 }}
            >
              E-post er påkrevet og må være gyldig
            </p>
          )}
        </InputBox>

        <InputBox
          htmlFor="phoneNumber"
          label="Telefon"
          className="mt-6 sm:mt-8"
        >
          <input
            className="text-center w-full tracking-tight text-xl"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Skriv inn ditt telefonnummer"
            ref={register({ required: false })}
          />

          {errors.phoneNumber && (
            <p
              className="text-red-600 text-xs w-full absolute inset-x-0"
              style={{ bottom: -22 }}
            >
              E-post er påkrevet og må være gyldig
            </p>
          )}
        </InputBox>

        <InputBox
          htmlFor="message"
          label="Kommentar"
          className="mt-6 sm:mt-8"
        >
          <textarea
            className="text-center w-full tracking-tight text-xl"
            rows={3}
            name="message"
            id="message"
            placeholder="Skriv inn en kommentar"
            ref={register({ required: false })}
          />
        </InputBox>

        <div className="mt-2 text-left">
          <div className="mt-2">
            <label
              htmlFor="sms"
              className="flex items-center tracking-tight text-lg"
            >
              <input
                type="checkbox"
                name="sms"
                id="sms"
                className="mr-4"
                ref={register({ required: false })}
              />

              Jeg ønsker budvarsel på SMS
            </label>
          </div>

          <div className="mt-2">
            <label
              htmlFor="tips"
              className="flex items-center tracking-tight text-lg"
            >
              <input
                type="checkbox"
                name="tips"
                id="tips"
                className="mr-4"
                ref={register({ required: false })}
              />

              Jeg ønsker tips per e-post
            </label>
          </div>

          <div className="mt-2">
            <label
              htmlFor="newsletter"
              className="flex items-center tracking-tight text-lg"
            >
              <input
                type="checkbox"
                name="newsletter"
                id="newsletter"
                className="mr-4"
                ref={register({ required: false })}
              />

              Jeg ønsker å motta nyhetsbrev per e-post
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="w-full mt-6 sm:mt-8 font-semibold py-2 uppercase tracking-wide block text-white bg-alt hover:opacity-50"
          id="signup-form-button"
        >
          Send inn
        </button>
      </form>

      <GdprDialog
        shown={showGdprDialog}
        onAccept={submit}
        onDeny={() => setShowGdprDialog(false)}
        isLoading={isLoading}
        description="Dette skjemaet samler inn ditt navn, din e-post adresse og ditt telefonnummer. Da kan enten vi eller vår megler kontakte deg under behandlingen av henvendelsen."
        consents={[{
          question: "Jeg samtykker til at Bonum kan samle på mitt navn, e-post adresse og telefonnummer.",
          required: true
        }, {
          question: "Jeg samtykker til at jeg kan bli kontaktet av megler.",
          required: true
        }]}
      />
    </>
  )
}

export default InterestForm
