import { createGlobalStyle } from "styled-components"
import palette from "./palette"
import "./resets.css"
import "./styles.css"

export default createGlobalStyle`
  body {
    color: ${palette.fg};
  }

  a {
    color: inherit;
    &:hover {
      color: #9da3a9;
    }
  }

  .accent-left::before {
    background-color: ${palette.accent};
  }
`
