import React from "react"
import Img, { FluidObject } from "gatsby-image"

export interface ImageProps {
  src: string | {
    extension?: string
    publicURL?: string
    childImageSharp: {
      fluid: FluidObject
    }
  }
  alt?: string
  style?: object
  className?: string
  imgStyle?: object
  loading?: "auto" | "lazy" | "eager"
  fadeIn?: boolean
}

const Image: React.FC<ImageProps> = (props) => {
  let img = null

  if(typeof props.src === "string") {
    img = <img
      src={props.src}
      alt={props.alt}
      style={props.style}
      className={props.className}
    />
  } else if (props.src.extension === "svg") {
    img = <img
      src={props.src.publicURL}
      alt={props.alt}
      style={props.style}
      className={props.className}
    />
  } else {
    img = <Img
      fluid={props.src.childImageSharp.fluid}
      alt={props.alt}
      style={props.style}
      imgStyle={props.imgStyle}
      className={props.className}
      loading="eager"
      fadeIn={false}
    />
  }

  return img
}

export default Image
