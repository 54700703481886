import React from "react"
import styled from "styled-components"

const IconSvg = styled.svg`
  animation: rotate 1s linear infinite;
  width: 50px;
  height: 50px;
  & .path {
    stroke: #5652bf;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

const Icon = () => (
  <IconSvg viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"
    />
  </IconSvg>
)

type SpinnerProps = {
  isLoading: boolean
}

const Spinner: React.FC<SpinnerProps> = ({ isLoading }) => {
  return isLoading ? (
    <div
      className="absolute z-10 inset-0 flex flex-col items-center justify-center"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.93)" }}
    >
      <Icon />

      <span
        className="mt-4 uppercase text-sm tracking-widest"
        style={{ fontFamily: "sans-serif" }}
      >
        Laster...
      </span>
    </div>
  ) : null
}

export default Spinner
