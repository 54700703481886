import React, { useState, useEffect } from "react"
import Dialog from "./Dialog"
import Spinner from "./Spinner"

interface C {
  question: string,
  required: boolean,
  value: boolean
}

interface Consent {
  question: string,
  required: boolean
}

interface GdprDialogProps {
  shown: boolean,
  onAccept: Function,
  onDeny: Function,
  isLoading: boolean,
  description: string,
  consents: Consent[]
}

const GdprDialog: React.FC<GdprDialogProps> = ({
  shown, onAccept, onDeny, isLoading, description, consents
}) => {
  const [ c, setC ] = useState<C[]>([])
  const [ hasRequiredConsents, setHasRequiredConsents ] = useState(false)

  useEffect(() => {
    setC(consents.map(
      (consent) => ({ ...consent, value: false })
    ))
  }, [ consents ])

  const onChangeConsent = (index: number, value: boolean) => {
    setC(c.map((consent, i) => {
      if(i === index) consent.value = value
      return consent
    }))

    const accepts = c.filter(
      ({ value, required }) => value === true && required === true
    )

    const required = c.filter(
      ({ required }) => required === true
    )

    if(accepts.length >= required.length) {
      setHasRequiredConsents(true)
    }
  }

  return (
    <Dialog
      title="Vennligst les før du fortsetter"
      shown={shown}
      width={400}
      onClose={() => { onDeny() }}
      closeOnOverlay={false}
    >
      <Spinner isLoading={isLoading} />

      <div className="py-6 px-8 bg-white text-black text-lg">
        <p className="mb-4">{description}</p>

        <p>Du kan lese mer om <a id="gdpr-personvern-link" href="https://bonum.no/personvern" target="_blank" rel="noopener" className="underline text-accent hover:text-black">vårt forhold til personvern</a>, for å finne ut om hvordan vi best ivaretar din informasjon.</p>

        <div className="my-10">
          {c.map((consent: Consent, index: number) => (
            <div
              className="flex items-center mt-4"
              key={index}
            >
              <input
                id={`consent-${index}`}
                name={`consent-${index}`}
                type="checkbox"
                onChange={(e) => onChangeConsent(index, e.target.checked)}
              />

              <label
                className="ml-6"
                htmlFor={`consent-${index}`}
              >
                {consent.question}
              </label>
            </div>
          ))}
        </div>

        <div className="flex flex-col">
          <button
            className="w-full text-base font-semibold py-2 uppercase tracking-wide block text-white bg-alt hover:opacity-50 disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => { onAccept() }}
            disabled={!hasRequiredConsents}
            id="gdpr-accept-button"
          >
            Jeg aksepterer
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default GdprDialog
