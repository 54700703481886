import React, { useEffect, useState } from "react"
// import ReactDOM from "react-dom"
// import { disableElementScroll, enableElementScroll } from "../utils/lockscroll"

export interface DialogProps {
  title?: React.ReactNode | string,
  shown: boolean,
  children: React.ReactNode,
  onClose?: Function,
  onOpen?: Function,
  width?: number | string,
  toBody?: boolean,
  alwaysRender?: boolean,
  floatingTitle?: boolean,
  showCloseButton?: boolean,
  closeOnOverlay?: boolean,
  style?: Object
}

const Dialog: React.FC<DialogProps> = ({
  title,
  shown,
  children,
  onClose,
  onOpen,
  width,
  toBody,
  alwaysRender,
  floatingTitle,
  showCloseButton,
  closeOnOverlay,
  style
}) => {
  const [ fullscreen, setFullscreen ] = useState<boolean>(false)

  const close = () => {
    // enableElementScroll(document.body)
    shown = false
    if(onClose) onClose()
  }

  const open = () => {
    // disableElementScroll(document.body)
    if(onOpen) onOpen()
  }

  useEffect(() => {
    if(shown) open()

    const keydown = (e: KeyboardEvent) => {
      if(e.keyCode !== 27) return
      close()
    }

    const onMatch = (e: MediaQueryList | MediaQueryListEvent) => {
      setFullscreen(e.matches)
    }
    const mql = window.matchMedia(
      `(max-width: ${width}${typeof width === "number" ? 'px' : ''})`
    )

    mql.addListener(onMatch)
    onMatch(mql)
    document.addEventListener("keydown", keydown, false)
    return () => {
      mql.removeListener(onMatch)
      document.removeEventListener("keydown", keydown, false)
    }
  }, [ shown ])

  const Dialog = (
    <div
      className={`fixed z-20 inset-0 w-full h-full overflow-y-auto scrolling-touch flex ${fullscreen ? 'py-0' : 'py-10'}`}
      style={{
        backgroundColor: "rgba(30, 30, 30, 0.85)",
        display: shown ? "flex" : "none",
        ...style
      }}
      onClick={() => { if(closeOnOverlay) close() }}
    >
      <div
        className={`self-center mx-auto shadow bg-black text-white text-base w-full  ${fullscreen ? 'rounded-none h-full overflow-y-auto' : 'rounded-xl'} ${floatingTitle ? "relative" : ""}`}
        style={{ maxWidth: width }}
        onClick={(e) => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
        }}
      >
        <div className={`flex items-center justify-between z-10 ${floatingTitle ? "bg-black absolute top-0 right-0 rounded-tr-lg" : "border-b border-solid border-gray-900"} ${title ? "py-3 px-6" : "hover:bg-white hover:text-black"}`}>
          {title && (
            <h2 className="dialog-h2 font-bold text-lg mb-0">
              {title}
            </h2>
          )}
          {showCloseButton && (
            <button
              className={`text-3xl leading-none cursor-pointer ${title ? "" : "py-2 px-4"}`}
              onClick={() => close()}
            >
              &times;
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  )

  return alwaysRender === false
    ? shown
      ? Dialog
      : null
    : Dialog

  // return toBody
  //   ? ReactDOM.createPortal(Dialog, document.body)
  //   : Dialog
}

Dialog.defaultProps = {
  title: null,
  shown: false,
  width: 900,
  toBody: false,
  alwaysRender: false,
  floatingTitle: false,
  showCloseButton: true,
  closeOnOverlay: true,
  style: {}
}

export default Dialog
